.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

[class^="swiper-button-"] {
  color: #000000;
}

.swiper-pagination-bullets {
  bottom: 0 !important;
}

.swiper-pagination-bullet-active {
  background: #4B5830;
}
.reviews .swiper-pagination-bullet-active {
  background: #ffffff;
}
.reviews  [class^="swiper-button-"] {
  color: #ffffff;
}

@media screen and (min-width: 1440px) {
  .swiperPrice {
    padding: 0 45px;
  }

  .swiperTeacher .swiper-wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
}
